<template>
  <div>
    <AppSettings />
  </div>
</template>

<script>
import AppSettings from '../components/application/AppSettings';

export default {
    components: {
        AppSettings
    }
};
</script>

<style>


.has-light-bg {
  background: #ededed !important;
}

.has-insert-shadow {
  -webkit-box-shadow: -10px 9px 15px -6px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 9px 15px -6px rgba(0, 0, 0, 0.1);
}
</style>